<script>
import SearchAndActions from "@/components/Table/SearchAndActions.vue";
import Header from "@/components/Table/Header.vue";
import axios from "axios";
import DataTable from "@/components/Table/DataTable.vue";
import ActionsButton from "@/components/Table/ActionsButton.vue";
import TablePagination from "@/components/Table/TablePagination.vue";
import EditTeam from "@/components/teams/EditTeam.vue";
import CreateTeam from "@/components/teams/CreateTeam.vue";
import StarRating from "@/components/teams/partials/RatingStars.vue";

export default {
  name: "Teams",
  components: {CreateTeam, EditTeam, TablePagination, ActionsButton, DataTable, Header, SearchAndActions, StarRating},
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Teams",
        path: "/teams",
        isActive: true
      }],
      tableData: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page: 0,
      },
      search: null,
      sort: '-created_at',
      date: null,

      createTeamModal: false,
      editTeamModal: false,
      team: {
        id: "",
        name: "",
        type: "",
        locations: "",
        userIds: [],
      },
      config: {
        rating: 0,
        isIndicatorActive: true,
        starStyle: {
          fullStarColor: "#ed8a19",
          emptyStarColor: "#737373",
          starWidth: 17,
          starHeight: 15,
        },
      },
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  mounted() {
    let page = this.$route.query.page ? this.$route.query.page : 1;
    let per_page = this.$route.query.per_page ? this.$route.query.per_page : 10;
    let search = this.$route.query.search ? this.$route.query.search : null;
    let sort = this.$route.query.sort ? this.$route.query.sort : '-created_at';
    let date = this.$route.query.date ? this.$route.query.date : null;

    this.getListData(page, per_page, search, sort, date);
  },
  methods: {
    getListData(page = 1, per_page = 10, search = null, sort = '-created_at', date = null) {
      let url = '/admin/teams-list';
      let params = {
        page: page,
        per_page: per_page ? per_page : this.pagination.per_page,
        search: search ? search : this.search,
        sort: sort,
        date: date ? date : this.date,
      };

      axios.get(url, {params})
          .then(response => {
            this.tableData = response.data.data;
            this.pagination = response.data.meta;
            this.sort = sort;

            this.$router.replace({query: params});
          })
    },
    onPageChange(page) {
      this.getListData(page, this.pagination.per_page, this.search, this.sort, this.date);
    },
    onPageSizeChanged(pageSize) {
      this.getListData(1, pageSize, this.search, this.sort, this.date);
    },
    onSearch(search) {
      this.search = search ? search : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    sortBy(field) {
      let sort = this.sort === field ? '-' + field : field;
      this.getListData(1, this.pagination.per_page, this.search, sort, this.date);
    },
    onDate(date) {
      this.date = date ? date.join(',') : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    dismissCreateTeamModal() {
      this.getListData();
      this.createTeamModal = false;
    },
    dismissEditTeamModal() {
      this.getListData();
      this.editTeamModal = false;
    },
    editTeam(team) {
      this.team = team;
      this.editTeamModal = true;
    },
    handleActions(command) {
      if (command.action === 'edit') {
        this.editTeam(command.data);
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-uppercase font-weight-bold text-dark">Teams</h6>
      </div>

      <div class="col-md-12">
        <div class="card p-4">
          <SearchAndActions
              :show-import="false"
              @search="onSearch"
              @date="onDate"
              action-url="/admin/teams-list"
              download-file-name="teams"
          >
            <el-button
                v-if="checkUsrPermissions(['Consumer_', 'Consumer_all'])"
                @click="$router.push('/consumers')"
                size="small"
                class="ml-2"
            >
              Consumers
            </el-button>
            <el-button
                v-if="checkUsrPermissions(['Team_all', 'Team_create'])"
                type="primary"
                @click="createTeamModal = true"
                size="small"
                class="ml-2"
                icon="el-icon-plus"
            >
              Add New
            </el-button>
          </SearchAndActions>

          <div class="row">
            <div class="col-md-12">
              <DataTable>
                <template #header>
                  <Header :sort="false" title="Sl"/>
                  <Header active="project_id" :ordering="sort" @sortBy="sortBy('project_id')" title="Project"/>
                  <Header active="name" :ordering="sort" @sortBy="sortBy('name')" title="Name"/>
                  <Header active="rating" :ordering="sort" @sortBy="sortBy('rating')" title="Rating"/>
                  <Header :sort="false" title="No of Members"/>
                  <Header
                      :sort="false"
                      title="Actions"
                      v-if="
                          checkUsrPermissions([
                            'Team_all', 'Team_edit'
                          ])
                        "
                  />
                </template>

                <tr
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                  <th scope="row">
                    {{ pagination.from + index }}
                  </th>
                  <td>
                    {{ data.project ? data.project.name : ""}}
                  </td>
                  <td>
                    {{ data.name }}
                  </td>
                  <td>
                    <star-rating v-bind="config" :rating="team.rating">
                    </star-rating>
                  </td>
                  <td>
                    {{ data.users.length }}
                  </td>
                  <td
                      v-if="
                          checkUsrPermissions([
                            'Team_all', 'Team_edit'
                          ])
                        "
                  >
                    <el-dropdown @command="handleActions">
                      <div class="el-dropdown-link">
                        Actions <i class="el-icon-arrow-down el-icon--right"></i>
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-if="checkUsrPermissions(['Team_all', 'Team_edit'])"
                            :command="{action: 'edit', data: data}"
                        >
                          Edit
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </td>
                </tr>
              </DataTable>

              <div class="mt-5" v-if="tableData.length">
                <TablePagination
                    :pagination="pagination"
                    @pagechanged="onPageChange"
                    @pagesizechanged="onPageSizeChanged"
                />
              </div>

              <div v-else>
                <p class="text-center font-weight-bold">
                  No data found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CModal
        :closeOnBackdrop="false"
        v-if="createTeamModal"
        title="Add Team"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="createTeamModal"
    >
      <CreateTeam @onSavedTeam="dismissCreateTeamModal"></CreateTeam>
    </CModal>

    <CModal
        :closeOnBackdrop="false"
        v-if="editTeamModal"
        title="Edit Team"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="editTeamModal"
    >
      <EditTeam
          @onEditedTeam="dismissEditTeamModal"
          :editedTeam="team"
      ></EditTeam>
    </CModal>
  </div>
</template>