var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',[(_vm.serverErrors.length)?_c('div',[_c('CAlert',{attrs:{"color":"warning","closeButton":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_c('ul',_vm._l((_vm.serverErrors),function(err){return _c('li',{key:err},[_vm._v(_vm._s(err))])}),0)])],1):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitted.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.projectsList)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Projects")]),_c('multiselect',{attrs:{"options":_vm.projectsList,"multiple":false,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"Select Project","label":"name","track-by":"id","preselect-first":false},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}],null,false,249740006),model:{value:(_vm.team.project),callback:function ($$v) {_vm.$set(_vm.team, "project", $$v)},expression:"team.project"}})],1):_vm._e()]),_c('div',{staticClass:"col-md-12"},[(_vm.usersList)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Members")]),_c('multiselect',{attrs:{"options":_vm.usersList,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"Select Team Members","custom-label":_vm.fullName,"label":"first_name","track-by":"id","preselect-first":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}],null,false,249740006),model:{value:(_vm.team.users),callback:function ($$v) {_vm.$set(_vm.team, "users", $$v)},expression:"team.users"}})],1):_vm._e()]),_c('div',{staticClass:"col-md-12 col-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.team.name),expression:"team.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter team name","required":""},domProps:{"value":(_vm.team.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.team, "name", $event.target.value)}}})])])]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Submit")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }