<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
         <div class="row">
           <div class="col-md-12">
             <div class="form-group" v-if="projectsList">
               <label>Projects</label>
               <multiselect
                   v-model="team.project"
                   :options="projectsList"
                   :multiple="false"
                   :close-on-select="true"
                   :clear-on-select="false"
                   :preserve-search="true"
                   placeholder="Select Project"
                   label="name"
                   track-by="id"
                   :preselect-first="false"
               >
                 <template
                     slot="selection"
                     slot-scope="{ values, search, isOpen }"
                 ><span
                     class="multiselect__single"
                     v-if="values.length &amp;&amp; !isOpen"
                 >{{ values.length }} options selected</span
                 ></template
                 >
               </multiselect>
             </div>
           </div>
          <div class="col-md-12">
            <div class="form-group" v-if="usersList">
              <label>Members</label>
              <multiselect v-model="team.users" :options="usersList" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Team Members" :custom-label="fullName" label="first_name" track-by="id" :preselect-first="true">
                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
              </multiselect>
            </div>
          </div>

          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Name</label>
              <input
                type="text"
                class="form-control"
                v-model="team.name"
                placeholder="Enter team name"
                required
              />
            </div>
          </div>
          <!-- <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Type</label>
              <select class="form-control" v-model="team.type" required>
                <option value="">Select One</option>
                <option value="hardware">Hardware</option>
                <option value="qa">QA</option>
                <option value="support">Support</option>
              </select>
            </div>
          </div> -->

          <!-- <div class="col-md-6 col-12">
            <div class="form-group">
              <label>District</label>
              <select
                name="area"
                v-model="team.district"
                class="form-control"
                required
              >
                <option value="">Select</option>
                <option value="Chattagram">Chattagram</option>
                <option value="Sylhet">Sylhet</option>
              </select>
            </div>
          </div>

          <div class="col-md-6 col-12">
             <div class="form-group">
              <label>Area</label>
              <select
                name="area"
                v-model="team.area"
                class="form-control"
                required
              >
                <option value="">Select</option>
                <option
                    v-for="(area, key) in areas[team.district]"
                    :key="key"
                    :value="area"
                    >{{ area }}</option
                  >
              </select>
            </div>
          </div> -->

        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters } from "vuex";

export default {
  name: "EditTeam",
  props: {
    editedTeam: Object,
  },
  data: () => {
    return {
      toastr: "",
      showToast: false,
      team: {
        id: null,
        name: "",
        type: "",
        district: "",
        area:"",
        project : {},
        users: [],
      },
       areas: {
        Chattagram: [
          "Agrabad",
          "Dampara",
          "Halishahar",
          "Kalurghat",
          "Jubilee Road",
        ],
        Sylhet: [
          "Kotwali Model",
          "Jalalabad",
          "Dakshin Surma",
          "Bimanbondar",
          "Shah Paran",
        ],
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  components: {
    Multiselect
  },
  methods: {
    fullName ({ first_name, last_name }) {
      return `${first_name} ${last_name}`
    },
    onSubmitted() {
      this.team.project_id = this.team.project ? this.team.project.id : "";
      this.team.userIds = this.team.users.map(a => a.id);
      this.$store
        .dispatch("Teams/updateTeam", this.team)
        .then(() => {
          this.showToast = true;
          this.$toastr.s('success', "Team successfully updated.", "Team Updated");
          setTimeout(() => {
            this.$emit("onEditedTeam");
          }, 500);
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
          }

          let errors = err.response.data.errors;
          for (let field of Object.keys(errors)) {
            this.showToast = true;
            this.$toastr.e("error, " + errors[field][0] + ", Error!");
          }
        });
    },
  },
  computed: {
    ...mapGetters("Users", ["usersList"]),
    ...mapGetters("Projects", ["projectsList"]),
  },
  mounted() {
    this.$store.dispatch("Users/getUsersList");
    this.$store.dispatch("Projects/getProjectsList");
    this.team = { ...this.editedTeam };
  },
};
</script>

<style scoped>
</style>
